<template>
  <b-card title="Müşteri Bilgisi">
    <b-list-group>
      <list-item
        v-if="customer.company"
        label="Firma Adı"
        :value="customer.company"
      />
      <list-item
        v-if="customer.phone"
        label="Telefon"
        :value="customer.phone"
      />
      <list-item
        v-if="customer.email"
        label="E-Posta"
        :value="customer.email"
      />
      <list-item
        v-if="customer.customer_user"
        label="İlgili Kişi"
        :value="customer.customer_user"
      />
      <list-item
        v-if="customer.customer_user_email"
        label="İlgili E-Posta"
        :value="customer.customer_user_email"
      />
      <list-item
        v-if="customer.customer_address"
        label="Bulunduğu Şube"
        :value="customer.customer_address"
      />
    </b-list-group>
  </b-card>
</template>

<script>
import { BCard, BListGroup } from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import ListItem from '@/views/Machines/View/ListItem'

export default {
  name: 'Customer',
  components: {
    BCard,
    BListGroup,
    ListItem,
  },
  computed: {
    customer() {
      return this.$store.getters['machines/getMachines']
    },
  },
}
</script>

<style scoped></style>
