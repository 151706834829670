<template>
  <b-card title="Servis Geçmişi">
    <app-collapse
      v-if="services.length > 0"
      accordion
    >
      <app-collapse-item
        v-for="(service,key) in services"
        :key="key"
        :title="service.work_type + ' / ' + service.job + ' - ' + moment(service.startDate).format('LL')"
      >
        <service-card :service-data="service" />
      </app-collapse-item>
    </app-collapse>
    <div v-else>
      <b-alert
        variant="info"
        show
      >
        <div class="alert-body text-center">
          <FeatherIcon
            icon="InfoIcon"
            size="20"
          />
          <p>Makine için servis kaydı bulunamadı.</p>
        </div>
      </b-alert>
    </div>
  </b-card>
</template>
<script>
import { BCard, BAlert } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
// eslint-disable-next-line import/extensions
import ServiceCard from '@/views/Machines/View/ServiceCard'

export default {
  name: 'ServiceHistory',
  components: {
    BCard,
    BAlert,
    AppCollapse,
    AppCollapseItem,
    ServiceCard,
  },
  computed: {
    services() {
      return this.$store.getters['services/getServices']
    },
  },
  created() {
    this.getServices()
  },
  methods: {
    getServices() {
      this.$store.dispatch('services/servicesList', {
        where: {
          'services.id_machines': this.$route.params.id,
        },
      })
    },
  },
}
</script>

<style scoped>

</style>
