<template>
  <b-row>
    <b-col
      xs="12"
      md="4"
    >
      <customer />
    </b-col>
    <b-col
      xs="12"
      md="8"
    >
      <machine />
    </b-col>
    <b-col cols="12">
      <service-history />
    </b-col>
  </b-row>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import Machine from '@/views/Machines/View/Machine'
// eslint-disable-next-line import/extensions
import Customer from '@/views/Machines/View/Customer'
// eslint-disable-next-line import/extensions
import ServiceHistory from '@/views/Machines/View/ServiceHistory'

export default {
  name: 'View',
  components: {
    BRow,
    BCol,
    Machine,
    Customer,
    ServiceHistory,
  },
  created() {
    this.getMachine()
  },
  methods: {
    getMachine() {
      this.$store.dispatch('machines/machinesList', {
        select: [
          'machines.id              AS id',
          'machines.mcode           AS mcode',
          'machines.project         AS project',
          'machines.serial          AS serial',
          'machines.syear           AS syear',
          'machines.notes           AS notes',
          'machine_types.title      AS machine_type',
          'machine_models.title     AS machine_model',
          'machine_cleaning.title   AS machine_cleaning',
          'machine_statuses.title   AS machine_status',
          'customers.company        AS company',
          'customers.phone          AS phone',
          'customers.email          AS email',
          'customer_users.name      AS customer_user',
          'customer_users.email     AS customer_user_email',
          'customer_address.title   AS customer_address',
          'users.name               AS username',
          'machines.created         AS created',
          'machines.modified        AS modified',
        ],
        where: {
          'machines.id': this.$route.params.id,
        },
        array: false,
      })
    },
  },
}
</script>

<style scoped>

</style>
